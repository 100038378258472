<template>
  <div class="bg-surface rounded-xl p-4">
    <div
      class="flex flex-col lg:flex-row lg:justify-between lg:items-center flex-wrap gap-3"
    >
      <SocialTypeInfo :social-type="props.social.type" />
      <div class="flex gap-3 flex-wrap justify-between">
        <div class="flex gap-3">
          <Btn
            v-if="singleSocialFileUrl && props.social.showDownloadButton"
            icon
            :size="BtnSize.LG"
            :variant="BtnVariant.OUTLINED"
            :shape="BtnShape.ROUNDED"
            @click="downloadContentFile(props.social)"
          >
            <Icon :icon="DownloadSvg" />
          </Btn>
        </div>
        <Dropdown>
          <template #activator="{ toggleDropdownVisibility }">
            <Btn
              :size="BtnSize.LG"
              :variant="BtnVariant.FILLED"
              :color="BtnColor.PRIMARY"
              @click="toggleDropdownVisibility"
            >
              {{ $t('content.post') }}
              <Icon class="ml-2" :icon="ArrowDownSvg" />
            </Btn>
          </template>
          <template #default>
            <button class="dropdown-option" @click="handleSocialPost">
              {{ $t('content.post-now') }}
            </button>
            <Activator
              v-if="authUser.isLoggedIn || props.displayPostLaterAndEdit"
              #="{ isActive, activate, deactivate }"
            >
              <button class="dropdown-option" @click="activateModal(activate)">
                {{ $t('content.post-later') }}
              </button>

              <EditPostModal
                v-if="isActive"
                :social-data="props.social"
                :hub="props.hub"
                :content="props.content"
                :content-data="{
                  hubSlug: props.hub?.slug,
                  contentSlug: props.content?.slug,
                  schedule: true,
                }"
                @close="deactivate"
              />
            </Activator>
            <Activator
              v-if="
                (props.social.isEditable || props.social.isCustomizable) &&
                (authUser.isLoggedIn || props.displayPostLaterAndEdit)
              "
              #="{ isActive, activate, deactivate }"
            >
              <button class="dropdown-option" @click="activateModal(activate)">
                {{
                  props.social.isCustomizable
                    ? $t('common.personalize')
                    : $t('common.edit')
                }}
              </button>

              <EditPostModal
                v-if="isActive"
                :social-data="props.social"
                :hub="props.hub"
                :content="props.content"
                :content-data="{
                  hubSlug: props.hub?.slug,
                  contentSlug: props.content?.slug,
                  schedule: false,
                }"
                @close="deactivate"
              />
            </Activator>
          </template>
        </Dropdown>
      </div>
    </div>
    <hr class="mt-5" />
    <div v-if="!props.social.isCustomizable" class="mt-5 whitespace-pre-wrap">
      {{ props.social.description }}
    </div>
    <div v-else class="mt-5">
      <Description
        :description="props.social.description"
        enable-values
        @update:error-state="descriptionErrorState = $event"
      />
    </div>
    <template v-if="props.social.socialFiles.length > 0">
      <template v-if="carouselFiles.length > 1">
        <CarouselGridPreviewModal
          :social-files="social.socialFiles"
          :social-type="props.social.type"
        >
          <Carousel
            :variant="socialTypeCarousel[props.social.type]"
            :files="carouselFiles"
            :post-data="props.social"
            class="flex-grow mt-5"
          />
        </CarouselGridPreviewModal>
      </template>
      <div
        v-else-if="isFileImage(singleSocialFileUrl)"
        class="aspect-square mt-5 w-full relative overflow-hidden"
      >
        <img
          :src="singleSocialFileUrl"
          class="rounded-lg object-contain w-full h-full relative z-10"
          alt="Image File"
        />
        <div
          class="absolute -top-5 -left-5 w-[110%] h-[110%] z-0"
          :style="`background-image: url(${singleSocialFileUrl}); filter: blur(10px);`"
        />
        <div class="absolute bottom-2 left-2 flex items-center gap-2 z-10">
          <Chip
            v-if="social.isCustomizable"
            :size="ChipSize.SM"
            :variant="ChipVariant.FILLED"
            :icon="SettingsIcon"
            :color="ChipColor.SECONDARY"
          >
            {{ $t('content.customizable') }}
          </Chip>
        </div>
      </div>
      <div
        v-else-if="isFileVideo(singleSocialFileUrl)"
        class="w-full rounded-lg aspect-video mt-5"
      >
        <video
          :src="singleSocialFileUrl"
          controls
          class="w-full h-full rounded-lg post-video"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Btn, BtnSize, BtnColor, BtnVariant, BtnShape } from '~/components/btn';
import { Icon } from '~/components/icon';
import {
  apiCreateContentSocialDraft,
  apiCreateSocialShareUrl,
  apiCreateSocialShareUrlFromDraft,
  ContentDto,
  ContentSocialType,
  CreateContentSocialDraftBodyDto,
} from '~/api/contents';
import DownloadSvg from '~/assets/icons/download.svg?component';
import { downloadFile } from '~/utils/file';
import { useSocialRedirectUrl, navigateTo, useRoute } from '#imports';
import {
  ContentMetricType,
  createContentLaunchMetric,
  createContentMetric,
  LaunchMetricType,
} from '~/api/metrics';
import { useLoader } from '~/composables/use-loader';
import { SocialTypeInfo } from '~/pages/_components/social-type-info';
import { Activator } from '~/components/activator';
import { EditPostModal } from '~/pages/my-posts/_components/edit-post-modal';
import { Dropdown } from '~/components/dropdown';
import ArrowDownSvg from '@/assets/icons/arrow-down.svg?component';
import { useAuthUserStore } from '~/store/auth-user';
import { Description } from '../../../../../description';
import { useCustomDescription } from '~/composables/use-custom-description';
import { Nullable } from '~/types/common';
import { Chip, ChipColor, ChipSize, ChipVariant } from '~/components/chip';
import SettingsIcon from '~/assets/icons/settings.svg?component';
import { useVariablesModalStore } from '~/store/variables-modal';
import { AccountType } from '~/api/accounts';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '~/store/account';
import { HubDto } from '~/api/hubs';
import { Carousel } from '~/components/carousel';
import { socialTypeCarousel } from '~/constants/social-posts';
import { CarouselGridPreviewModal } from '~/pages/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal';

type Props = {
  hub?: HubDto;
  content: ContentDto;
  social: NonNullable<ContentDto['socials']>[number];
  displayPostLaterAndEdit: boolean;
};
const props = defineProps<Props>();
const route = useRoute();
const descriptionErrorState = ref(null);

const authUser = useAuthUserStore();
const { currentAccount } = storeToRefs(useAccountStore());

const { setSubmitCallback } = useVariablesModalStore();
const { validateVariables, getCustomDescription, activateVariablesModal } =
  useCustomDescription(props.social.description);

const socialDownloadMetricMap: Record<ContentSocialType, ContentMetricType> = {
  // [ContentSocialType.TWITTER]: ContentMetricType.TWITTER_POST_DOWNLOAD,
  [ContentSocialType.FACEBOOK]: ContentMetricType.FACEBOOK_POST_DOWNLOAD,
  [ContentSocialType.LINKEDIN]: ContentMetricType.LINKEDIN_POST_DOWNLOAD,
  [ContentSocialType.INSTAGRAM]: ContentMetricType.INSTAGRAM_POST_DOWNLOAD,
};

const singleSocialFileUrl = computed(
  () => props.social.socialFiles[0]?.file?.url,
);

const handleDraftSave = async () => {
  const socialBody: CreateContentSocialDraftBodyDto = {
    contentSocialId: props.social.id,
    finishedAt: new Date().toISOString(),
    logos: [],
    description: props.social.description,
  };

  if (props.social.isEditable) {
    socialBody.description = props.social.description;
  }

  await apiCreateContentSocialDraft(socialBody);
};

const downloadContentFile = (socialPost: any) => {
  if (props.hub) {
    createContentMetric(
      props.hub.slug,
      props.content.slug,
      socialDownloadMetricMap[props.social.type],
    );
  }

  if (props.social.isCustomizable || props.social.isEditable) {
    handleDraftSave();
  }

  //TODO: consider ziping files
  socialPost.socialFiles.map(({ file }) => downloadFile(file.url));
};

const getSocialShareUrl = async (
  description?: string,
): Promise<Nullable<string>> => {
  if (props.hub) {
    const { shareUrl } = await apiCreateSocialShareUrl(
      props.hub.slug,
      props.content.slug,
      {
        contentSocialId: props.social.id,
        description,
      },
    );

    return shareUrl;
  }

  const { shareUrl } = await apiCreateSocialShareUrlFromDraft({
    contentSocialId: props.social.id,
    description,
    socialFiles: props.social.socialFiles.map(({ file, caption }) => ({
      caption: caption || null,
      fileId: file.id,
    })),
  });

  return shareUrl;
};

const handleSocialShareRedirect = async () => {
  const description = getCustomDescription();
  const shareUrl = await getSocialShareUrl(description);

  const { updateSocialRedirectUrl } = useSocialRedirectUrl();

  updateSocialRedirectUrl();

  if (shareUrl) {
    if (
      props.hub?.isLaunchEnabled &&
      currentAccount.value?.type === AccountType.STANDARD &&
      !props.content.launchData.completedAt
    ) {
      await createContentLaunchMetric(
        props.hub.slug,
        props.content.slug,
        LaunchMetricType.COMPLETED,
      );
      // eslint-disable-next-line vue/no-mutating-props
      props.content.launchData.completedAt = new Date();
    }

    globalThis.location.href = shareUrl;
  }
};

const handleSocialPost = async () => {
  await useLoader({
    action: async () => {
      if (props.social.isCustomizable) {
        await handleDraftSave();
      }

      if (descriptionErrorState.value) {
        const isDescriptionValid = validateVariables(
          descriptionErrorState.value,
        );

        if (!isDescriptionValid) {
          setSubmitCallback(handleSocialShareRedirect);
          activateVariablesModal(descriptionErrorState.value);
          return null;
        }
      }

      await handleSocialShareRedirect();
    },
  });
};

const activateModal = (activate: () => void) => {
  if (authUser.isLoggedIn) {
    activate();
  } else {
    navigateTo({
      path: '/auth/login',
      query: { redirect: route.fullPath },
    });
  }
};

const carouselFiles = computed(() =>
  props.social.socialFiles.map(({ file }) => ({
    id: file.id,
    src: file.url,
  })),
);
</script>

<style scoped>
.post-video {
  background: #000000;
}

.dropdown-option {
  @apply py-3 px-2 text-left w-full;
}
</style>
